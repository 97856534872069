
import { SEND_EMAIL } from "@/store/actions.type";
// import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import MainNavigation from "@/components/MainNavigation.vue";
import { defineComponent } from "vue";
import { CkEditor } from "@/common/contracts/ckeditor";

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default defineComponent({
  name: "CSNewEmail",
  components: {
    MainNavigation
  },
  props: {
    folderId: {
      type: String
    }
  },
  data() {
    return {
      currentPage: 1,

      editor: CkEditor.CustomClassicEditor,
      editorConfig: {},

      uploadedFiles: [],
      uploadError: null,
      currentStatus: null as any,
      uploadFieldName: "attachments",

      pendingEmail: {
        to: "",
        from: "",
        subject: "",
        body: "",
        attachments: [],
        isInternal: false
      },

      error: "" as any
    };
  },
  async mounted() {
    this.reset();
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    }
  },
  methods: {
    async sendEmail() {
      const response = await this.$store.dispatch(
        SEND_EMAIL,
        this.pendingEmail
      );
      if (response != null) {
        this.reset();
      }
    },
    reset() {
      this.error = null;
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
      this.pendingEmail = {
        to: "",
        from: "",
        subject: "",
        body: "",
        attachments: [],
        isInternal: false
      };
    },
    filesChange(fieldName, fileList) {
      if (!fileList.length) return;

      this.pendingEmail.attachments = fileList;
    }
  },
  sortFolders: function (arr) {
    function compare(a, b) {
      if (a.ordinal < b.ordinal) return -1;
      if (a.ordinal > b.ordinal) return 1;
      return 0;
    }

    return arr.sort(compare);
  }
});
